iframe[name="JSD widget"] {
  transform: translateX(10px) translateY(10px) !important;
  bottom: 12px !important;
  right: 12px !important;
  max-height: 0px !important;
  max-width: 0px !important;
}

iframe[name="JSD widget"].enable {
  max-height: none !important;
  max-width: none !important;
}

footer.ReactQueryDevtools > button {
  height: fit-content;
  display: none !important;
}

.react-grid-item img {
  pointer-events: auto !important;
  user-select: none;
}

body.disable-tap-highlight {
  -webkit-tap-highlight-color: transparent;
}

body.disable-select {
  -webkit-user-select: none;
  touch-action: none;
  user-select: none;
}

#root {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body.system-theme-dark .daterangepicker {
  background-color: #2e2e2e;
  color: white;
  border: 1px solid #444;
}

body.system-theme-dark .daterangepicker:before {
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #444;
}

body.system-theme-dark .daterangepicker select.monthselect {
  background-color: #2e2e2e;
}

body.system-theme-dark .daterangepicker select.yearselect {
  background-color: #2e2e2e;
}

body.system-theme-dark .daterangepicker:after {
  border-right: 6px solid transparent;
  border-bottom: 6px solid #444;
  border-left: 6px solid transparent;
}

body.system-theme-dark .daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #444;
}

body.system-theme-dark .daterangepicker .drp-buttons {
  border-top: 1px solid #444;
}

body.system-theme-dark .daterangepicker .calendar-table {
  border: 1px solid #2e2e2e;
  background-color: #2e2e2e;
}

body.system-theme-dark .daterangepicker td.in-range {
  background-color: #3c5763;
  color: #fff;
}

body.system-theme-dark .daterangepicker td.off,
body.system-theme-dark .daterangepicker td.off.in-range,
body.system-theme-dark .daterangepicker td.off.start-date,
body.system-theme-dark .daterangepicker td.off.end-date {
  background-color: #2e2e2e;
  border-color: transparent;
  color: #666;
}

body.system-theme-dark .daterangepicker .calendar-table .next span,
body.system-theme-dark .daterangepicker .calendar-table .prev span {
  color: black;
  border: solid white;
  border-width: 0 2px 2px 0;
}

body.system-theme-dark .daterangepicker td.available:hover,
body.system-theme-dark .daterangepicker th.available:hover {
  background-color: #666;
  border-color: transparent;
  color: inherit;
}

body.system-theme-dark .daterangepicker .ranges li:hover {
  background-color: #666;
}

body.system-theme-dark .daterangepicker select.hourselect,
body.system-theme-dark .daterangepicker select.minuteselect,
body.system-theme-dark .daterangepicker select.secondselect,
body.system-theme-dark .daterangepicker select.ampmselect {
  background: #444;
  border: 1px solid #444;
  border-radius: 5px;
}

body.system-theme-light .daterangepicker select.hourselect,
body.system-theme-light .daterangepicker select.minuteselect,
body.system-theme-light .daterangepicker select.secondselect,
body.system-theme-light .daterangepicker select.ampmselect {
  background: #eee;
  border: 1px solid #eee;
  border-radius: 5px;
}

body.system-theme-dark div[class*="StyledSelect__StyledContainer-sc-"] div[class*="SelectContainer__OptionsBox-sc-"] {
  background: #000;
  color: white;
}

body.system-theme-dark
  div[class*="StyledSelect__StyledContainer-sc-"]
  div[class*="SelectContainer__OptionsBox-sc-"]
  button[class*="SelectContainer__SelectOption-sc"]:hover {
  background: #333;
  color: white;
}

/* Hotfix to remove the autofill background from inputs */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}
