.h-32 {
  height: 34px !important;
}

.border-focused-white:focus {
  border: 1px solid white !important;
}
.border-focused-transparent {
  border: 1px solid transparent;
}
